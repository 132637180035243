// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-experience-pages-earth-iss-js": () => import("./../../../src/pages/experience-pages/earth-iss.js" /* webpackChunkName: "component---src-pages-experience-pages-earth-iss-js" */),
  "component---src-pages-experience-pages-iss-js": () => import("./../../../src/pages/experience-pages/iss.js" /* webpackChunkName: "component---src-pages-experience-pages-iss-js" */),
  "component---src-pages-experience-pages-mars-js": () => import("./../../../src/pages/experience-pages/mars.js" /* webpackChunkName: "component---src-pages-experience-pages-mars-js" */),
  "component---src-pages-experience-pages-solarsystem-js": () => import("./../../../src/pages/experience-pages/solarsystem.js" /* webpackChunkName: "component---src-pages-experience-pages-solarsystem-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-scanner-js": () => import("./../../../src/pages/scanner.js" /* webpackChunkName: "component---src-pages-scanner-js" */)
}

